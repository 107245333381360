import React from "react"
import "./index.scss"
import Layout from "../components/layout"

export default () => (
        <Layout pageId={"/iot"}>
            <div className="container-fluid" style={{ height: "35vw", background: "#3E6CED" }}/>
            <div className="container-fluid">
                <div className="row align-items-center" style={{paddingTop: 25, paddingBottom: 25}}>
                    <div className="col-lg-2 col-2 align-self-end">
                        <img src="../images/iot/1.png" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-lg-8 col-8">
                        <div className="row justify-content-center" style={{paddingTop: 30}}>
                            <div className="col-lg-3 col-3 product_item_1">
                                <img src="../images/iot/1_1.png" className="img-fluid" alt=""/>
                                <div className="product_item_title">数据大屏展示</div>

                            </div>
                            <div className="col-lg-3 col-3 product_item_1">
                                <img src="../images/iot/1_2.png" className="img-fluid" alt=""/>
                                <div className="product_item_title">运行状态实时监控</div>

                            </div>
                            <div className="col-lg-3 col-3 product_item_1">
                                <img src="../images/iot/1_3.png" className="img-fluid" alt=""/>
                                <div className="product_item_title">日志信息远程查看</div>

                            </div>
                            <div className="col-lg-3 col-3 product_item_1">
                                <img src="../images/iot/1_4.png" className="img-fluid" alt=""/>
                                <div className="product_item_title">数据报表定时推送</div>

                            </div>
                            <div className="col-lg-3 col-3 " style={{ marginTop: 30, textAlign: "center" }}>
                                <img src="../images/iot/1_5.png" className="img-fluid" alt=""/>
                                <div className="product_item_title">设备报警远程通知</div>

                            </div>
                            <div className="col-lg-3 col-3" style={{ marginTop: 30, textAlign: "center" }}>
                                <img src="../images/iot/1_6.png" className="img-fluid" alt=""/>
                                <div className="product_item_title">设备生命周期管理</div>

                            </div>
                            <div className="col-lg-3 col-3" style={{ marginTop: 30, textAlign: "center" }}>
                                <img src="../images/iot/1_7.png" className="img-fluid" alt=""/>
                                <div className="product_item_title">移动端随时随地管理</div>

                            </div>
                            <div className="col-lg-3 col-3" style={{ marginTop: 30, textAlign: "center" }}>
                                <img src="../images/iot/1_8.png" className="img-fluid" alt=""/>
                                <div className="product_item_title">规则引擎场景联动</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">

                    </div>
                </div>
                <div className="row justify-content-center" style={{ paddingTop: 25, paddingBottom: 25}}>
                    <div className="col-2"></div>
                    <div className="col-8">
                        <div className="row justify-content-center align-items-center" style={{paddingTop: 25, paddingBottom: 25}}>
                            <div style={{ height: 1, width: 80, backgroundColor: "#999", display: "inherit" }}/>
                            <div style={{ fontSize: 40, fontWeight: "bold", marginLeft: 15, marginRight: 15 }}>
                                智能消防
                            </div>
                            <div style={{ height: 1, width: 80, backgroundColor: "#999", display: "inherit" }}/>
                            <div style={{ marginTop: 30, color: "#999", textAlign: 'center'}}>
                                通过大数据、云计算、移动互联网等新一代信息技术，将消防设备监测到的数据实时传至云平台，平台对收集的数据进行监测、统计、分析和信息共享，做到事前预警，事中处理，全面提升社区消防安全管理水平和消防监督执法效能
                            </div>
                        </div>
                        <div className="row justify-content-center" style={{paddingTop: 25, paddingBottom: 25, marginTop: 50}}>
                            <div className="col-3" style={{ textAlign: "center" }}>
                                <img src="../images/iot/2_1.png" className="img-fluid" alt=""/>
                                <div style={{ fontSize: 16, color: "#333", marginTop: 20, marginBottom: 40 }}>防火监控系统</div>
                            </div>
                            <div className="col-3 " style={{ textAlign: "center" }}>
                                <img src="../images/iot/2_2.png" className="img-fluid" alt=""/>
                                <div style={{ fontSize: 16, color: "#333", marginTop: 20, marginBottom: 40 }}>气体灭火控制系统</div>
                            </div>
                            <div className="col-3 " style={{ textAlign: "center" }}>
                                <img src="../images/iot/2_3.png" className="img-fluid" alt=""/>
                                <div style={{ fontSize: 16, color: "#333", marginTop: 20, marginBottom: 40 }}>应急照明和疏散指示系统</div>
                            </div>
                            <div className="col-3" style={{ textAlign: "center" }}>
                                <img src="../images/iot/2_4.png" className="img-fluid" alt=""/>
                                <div style={{ fontSize: 16, color: "#333", marginTop: 20, marginBottom: 40 }}>自动报警</div>
                            </div>
                            <div className="col-3 " style={{ textAlign: "center" }}>
                                <img src="../images/iot/2_5.png" className="img-fluid" alt=""/>
                                <div style={{ fontSize: 16, color: "#333", marginTop: 20, marginBottom: 40 }}>消防水监控系统</div>
                            </div>
                            <div className="col-3 " style={{ textAlign: "center" }}>
                                <img src="../images/iot/2_6.png" className="img-fluid" alt=""/>
                                <div style={{ fontSize: 16, color: "#333", marginTop: 20, marginBottom: 40 }}>电气火灾监控系统</div>
                            </div>
                            <div className="col-3 " style={{ textAlign: "center" }}>
                                <img src="../images/iot/2_7.png" className="img-fluid" alt=""/>
                                <div style={{ fontSize: 16, color: "#333", marginTop: 20, marginBottom: 40 }}>消防设备监控系统</div>
                            </div>
                            <div className="col-3 " style={{ textAlign: "center" }}>
                                <img src="../images/iot/2_8.png" className="img-fluid" alt=""/>
                                <div style={{ fontSize: 16, color: "#333", marginTop: 20, marginBottom: 40}}>无线烟感监控系统</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <img src="../images/iot/2.png" className="img-fluid" alt=""/>
                    </div>
                </div>
                <div className="row align-items-center" style={{paddingTop: 25, paddingBottom: 25 }}>
                    <div className="col-6">
                        <img src="../images/iot/3.png" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-3">
                        <div style={{ fontSize: 50, fontWeight: "bold" }}>
                            01
                        </div>
                        <div style={{ fontSize: 35, fontWeight: "bold", marginTop: 10 }}>
                            能源管理
                        </div>
                        <div style={{ marginTop: 35, lineHeight: 2, color: "#999" }}>
                            通过物联网传感器监测各类设备的能源消耗，通过数据分析制定正确的能源开发政策，不断完善能源规划、能源控制系统提高能源利用率，实行能源定额管理。
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center" style={{paddingTop: 25, paddingBottom: 25}}>
                    <div className="col-3">
                        <div style={{ fontSize: 50, fontWeight: "bold" }}>
                            02
                        </div>
                        <div style={{ fontSize: 35, fontWeight: "bold", marginTop: 10 }}>
                            环境监控
                        </div>
                        <div style={{ marginTop: 35, lineHeight: 2, color: "#999" }}>
                            以监控各类环境指标的传感器为基础，使用NB-Iot,Lora等新一代物联网技术实时数据汇总，对环境进行全面监测，对环境分险预警决策全面感知和动态监控管理
                        </div>
                    </div>
                    <div className="col-4">
                        <img src="../images/iot/4.png" className="img-fluid" alt=""/>
                    </div>
                </div>
                <div className="row align-items-center" style={{paddingTop: 25, paddingBottom: 25}}>
                    <div className="col-6">
                        <img src="../images/iot/5.png" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-3">
                        <div style={{ fontSize: 50, fontWeight: "bold" }}>
                            03
                        </div>
                        <div style={{ fontSize: 35, fontWeight: "bold", marginTop: 10 }}>
                            智能消防监控
                        </div>
                        <div style={{ marginTop: 35, lineHeight: 2, color: "#999" }}>
                            利用物联网，大数据、云计算等新一代信息技术将消防设备监测数据实时传至云平台进行监测、统计分析和信息共享，做到事前预警，事中处理，全面提升社会 单元消防安全管理水平和消防监督执法效能
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
)
